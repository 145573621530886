export const sortMappingColors = {
  'Dark Blue': 'midnight-blue',
  Light: 'white',
  Dark: 'black',
  Blue: 'bahama-blue',
  'Light Blue': 'danube',
  'Dark Green': 'killarney',
  'Light Green': 'apple',
  Grey: 'dusty-gray',
  Red: 'monza',
  Yellow: 'hokey-pokey',
}

export const transparency = {
  tp_0: '0',
  tp_25: '25',
  tp_50: '50',
  tp_75: '75',
  tp_100: '100',
}

export const heroColors = {
  black: 'black',
  blue: 'midnight-blue',
}
