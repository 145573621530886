export const CAROUSELITEMS = [
  { name: 'Chromatin Profiling', url: '/applications/chip-and-chip-seq' },
  {
    name: 'ELISA',
    url: '/applications/elisa',
  },
  {
    name: 'Flow Cytometry',
    url: '/applications/flow-cytometry',
  },
  {
    name: 'Immunocytochemistry',
    url: '/applications/immunohistochemistry',
  },
  {
    name: 'Immunofluorescence',
    url: '/applications/immunofluorescence',
  },
  {
    name: 'Immunohistochemistry',
    url: '/applications/immunohistochemistry',
  },
  {
    name: 'Immunoprecipitation',
    url: '/applications/immunoprecipitation',
  },
  {
    name: 'Proteomics',
    url: '/services/proteomics-analytical-services',
  },
  {
    name: 'SignalStar™ Multiplex IHC',
    url: '/applications/signalstar-multiplex-ihc',
  },
  {
    name: 'Simple Western',
    url: 'https://learn.cellsignal.com/antibodies-validated-for-simple-western',
  },
  {
    name: 'Western Blot',
    url: '/applications/western-blot-immunoblot',
  },
]
